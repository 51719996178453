<template>
  <div class="box-company-info">
    <div>公司信息</div>
    <div class="box-group">
      <!-- 公司名称 -->
      <label for="companyname" class="control-label">公司名称 <span class="require">*</span></label>
      <div>
        <input id="companyname" v-model="firmInfo.CompanyName" type="text" class="form-control" :placeholder="placeholder.CompanyName" :disabled="isFormDisabled">
        <span style="color:red">
          <span>{{ checkField.companynameinfo }}</span>
        </span>
      </div>
    </div>
    <div class="box-group">
      <!-- 公司英文名称 -->
      <label for="companylocalname" class="control-label">公司英文名称 </label>
      <div>
        <input id="companylocalname" v-model="firmInfo.CompanyLocalName" type="text" class="form-control">
      </div>
    </div>
    <!-- 公司类型 -->
    <div class="box-group">
      <label class="control-label">
        公司类型
        <span class="require">*</span><br/>
        <span style="font-size: 12px">
          （可多选）
        </span>
      </label>
      <div style="height:198px;width: 489px;margin-left: 20px;">
        <div class="form-control" style="width:100%;height:100%;border: 1px solid #dbdbdb;">
          <div style="padding:0">
            <div class="checkbox-style">
              <span>
                <input v-model="CompanyType" value="_cattlefarm" type="checkbox" :disabled="isFormDisabled">肉牛养殖企业
              </span>
              <input v-model="CompanyType" value="_pigfarm" type="checkbox" :disabled="isFormDisabled">生猪养殖企业
            </div>
            <div class="checkbox-style">
              <span>
                <input v-model="CompanyType" value="_cattleslaughterhouse" type="checkbox" :disabled="isFormDisabled">肉牛屠宰加工企业
              </span>
              <input v-model="CompanyType" value="_pigslaughterhouse" type="checkbox" :disabled="isFormDisabled">生猪屠宰加工企业
            </div>
            <div class="checkbox-style">
              <span>
                <input v-model="CompanyType" value="_beefexporter" type="checkbox" :disabled="isFormDisabled">对华牛肉出口商
              </span>
              <input v-model="CompanyType" value="_porkexporter" type="checkbox" :disabled="isFormDisabled">对华猪肉出口商
            </div>
            <div class="checkbox-style">
              <span>
                <input v-model="CompanyType" value="_beefimporter" type="checkbox" :disabled="isFormDisabled">中国牛肉进口商
              </span>
              <input v-model="CompanyType" value="_porkimporter" type="checkbox" :disabled="isFormDisabled">中国猪肉进口商
            </div>
            <div class="checkbox-style">
              <span>
                <input v-model="CompanyType" value="_beefdistributer" type="checkbox" :disabled="isFormDisabled">中国牛肉批发商/贸易商
              </span>
              <input v-model="CompanyType" value="_porkdistributer" type="checkbox" :disabled="isFormDisabled">中国猪肉批发商/贸易商
            </div>
            <div class="checkbox-style">
              <span>
                <input v-model="CompanyType" value="_beefproductproducer" type="checkbox" :disabled="isFormDisabled">中国牛肉制品生产加工企业
              </span>
              <input v-model="CompanyType" value="_porkproductproducer" type="checkbox" :disabled="isFormDisabled">中国猪肉制品生产加工企业
            </div>
            <div class="checkbox-style">
              <span>
                <input v-model="CompanyType" value="_restaurant" type="checkbox" :disabled="isFormDisabled">中国餐饮企业/超市
              </span>
              <input v-model="CompanyType" value="_other" type="checkbox" :disabled="isFormDisabled">其他
            </div>
          </div>
        </div>
        <span style="color:red">
          <span>{{ checkField.companytypeinfo }}</span>
        </span>
      </div>
    </div>

    <div class="box-group">
      <!-- 涉及产品 -->
      <label class="control-label">涉及产品 <span class="require">*</span></label>
      <div style="width: 488px;height:35px;margin-left: 20px;background-color: #EDEDED;border-radius:5px;display: flex;align-items: center;font-size: 14px" class="checkbox-style">
        <input style="margin-left: 20px" v-model="ProductType" value="_beef" type="checkbox" :disabled="isFormDisabled">牛肉
        <input style="margin-left: 20px" v-model="ProductType" value="_pork" type="checkbox" :disabled="isFormDisabled">猪肉
      </div>
      <span style="color:red">
        <span>{{ checkField.producttypeinfo }}</span>
      </span>
    </div>

    <!-- 国家、直辖市 -->
    <div class="box-group">
      <label for="country" class="control-label">国家 <span class="require">*</span></label>
      <div style="width: 489px;display: flex;justify-content: flex-start;margin-left: 20px">
        <div>
          <select v-model="firmInfo.CompanyCountry"
                  class="form-control"
                  id="country"
                  :disabled="isFormDisabled">
            <option v-for="country in countrydata" :key="country" class="form-control" :value="country">
              {{ $t(`message["${country}"]`) }}
            </option>
          </select>
          <span style="color:red">
            <span>{{ checkField.countryinfo }}</span>
          </span>
        </div>
        <!--省/直辖市-->
        <div style="display: flex;flex-direction: column">
          <div style="display: flex">
            <label for="province" class="control-label"
                   style="width:150px!important;margin-right: 20px;text-align: right">省/直辖市 <span
              class="require">*</span></label>
            <select v-model="firmInfo.CompanyProvince"
                    class="form-control"
                    id="province"
                    :disabled="isFormDisabled">
              <option v-for="province in provincelist" :key="province" :value="province">
                {{ $t(`message["${province}"]`) }}
              </option>
            </select>
            <span style="color:red">
              <span>{{ checkField.provinceinfo }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 联系人、性别 -->
    <div class="box-group">
      <label for="contactpersonname" class="control-label">联系人 <span class="require">*</span></label>
      <div style="width: 489px;display: flex;justify-content: flex-start;margin-left: 20px">
        <div>
          <input style="width: 160px!important;margin-left: 0" v-model="firmInfo.ContactPersonName" type="text"
                 class="form-control" id="contactpersonname" :disabled="isFormDisabled" @blur="checkContact" @input="checkContact" :class="{ error : checkField.contactpersonnameinfo }">
          <span style="color:red; font-size: 12px">
            <span>{{ checkField.contactpersonnameinfo }}</span>
          </span>
        </div>
        <!--性别-->
        <div style="display: flex;flex-direction: column">
          <div style="display: flex">
            <label for="contactpersonsex" class="control-label"
                   style="width:150px!important;margin-right: 20px;text-align: right">性别 <span
              class="require">*</span></label>
            <select v-model="firmInfo.ContactPersonSex"
                    class="form-control"
                    id="contactpersonsex" :disabled="isFormDisabled">
              <option value="_mr">先生</option>
              <option value="_ms">女士</option>
            </select>
            <span style="color:red">
              <span>{{ checkField.contactpersonsexinfo }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="box-group">
      <!-- 昵称 -->
      <label for="nickname" class="control-label">昵称 </label>
      <div>
        <input id="nickname" v-model="firmInfo.NickName" type="text" class="form-control" :disabled="isFormDisabled">
      </div>
    </div>
    <div class="box-group">
      <!-- 联系人英文名字或拼音 -->
      <label for="contactpersonenglishname" class="control-label">联系人英文名字或拼音 </label>
      <div>
        <input id="contactpersonenglishname" v-model="firmInfo.ContactPersonEnglishName" type="text" class="form-control" :disabled="isFormDisabled">
      </div>
    </div>
    <div class="box-group">
      <!-- 手机 -->
      <label for="companyphone" class="control-label">手机 <span class="require">*</span></label>
      <div style="height: 35px">
        <input v-model="firmInfo.CompanyPhoneNumber" type="text" class="form-control" id="companyphone" :disabled="isFormDisabled" :placeholder="placeholder.Mobile" @blur="checkPhone" @input="checkPhone"  :class="{ error : checkField.companyphoneinfo }">
        <span style="color:red;font-size: 12px;margin-left: 20px">
          <span>{{ checkField.companyphoneinfo }}</span>
        </span>
      </div>
    </div>
    <div class="box-group">
      <!-- 传真 -->
      <label for="companyfax" class="control-label">传真 </label>
      <div>
        <input id="companyfax" v-model="firmInfo.CompanyFaxNumber" type="text" class="form-control" :disabled="isFormDisabled">
      </div>
    </div>
    <div class="box-group">
      <!-- 电话 -->
      <label for="cellphone" class="control-label">电话 </label>
      <div>
        <input v-model="firmInfo.ContactPersonCellPhoneNumber" type="text" class="form-control" id="cellphone"
               :placeholder="placeholder.Tel"
               :disabled="isFormDisabled">
      </div>
    </div>
    <div class="box-group">
      <!-- 邮箱 -->
      <label for="companyemail" class="control-label">邮箱 <span class="require">*</span></label>
      <div style="height: 35px">
        <input v-model="firmInfo.CompanyEmail" type="text" class="form-control" id="companyemail" :disabled="isFormDisabled" :placeholder="placeholder.Email" @blur="checkEmail" @input="checkEmail" :class="{ error : checkField.companyemailinfo }">
        <span style="color:red; font-size: 12px;margin-left: 20px">
          {{checkField.companyemailinfo}}
          <span>{{ checkField.companyemailinfo }}</span>
        </span>
      </div>
    </div>
    <div class="box-group">
      <!-- 网址 -->
      <label for="website" class="control-label">网址 </label>
      <div>
        <input v-model="firmInfo.CompanyWebSite" type="text" class="form-control" id="website"
               :placeholder="placeholder.Website"
               :disabled="isFormDisabled">
      </div>
    </div>
    <div class="box-group" style="margin-top: 20px;">
      <label/>
      <button v-if="isFormDisabled" type="button" class="btn btn-one" @click="isFormDisabled=false">修改公司信息</button>
      <button v-if="!isFormDisabled" type="button" class="btn btn-two" @click.prevent="updateData">保存</button>
      <button v-if="!isFormDisabled" type="button" class="btn btn-three" @click="isFormDisabled=true">取消</button>
    </div>

    <common-dialog id="companyInfoDialog" :message="dialogmsg"/>
  </div>
</template>

<script>
import CommonDialog from '@/components/cn/CommonDialog'
import { countrydata, provincedata } from '@/utils/dictionary'
import { getLocalStore } from '@/utils/common'

export default {
  components: {
    CommonDialog
  },
  name: 'CompanyInfo',
  data () {
    return {
      countrydata: countrydata,
      firmInfo: {
        CompanyType: '',
        ProductType: ''
      },
      checkField: {
        password: '',
        newpassword: '',
        renewpassword: '',
        contactpersonnameinfo: '',
        companyemailinfo: '',
        companyphoneinfo: '',
        producttypeinfo: ''
      },
      CompanyType: [],
      ProductType: [],
      isFormDisabled: true,
      dialogmsg: '',
      placeholder: {
        CompanyName: '',
        Website: '',
        Mobile: '',
        Tel: '',
        Email: ''
      }
    }
  },
  computed: {
    provincelist () {
      const province = this.firmInfo.CompanyCountry
      if (province && province !== '_othercountry') {
        return provincedata[province].split('|')
      }
      return []
    }
  },
  mounted () {
    this.getUserInfo()
  },
  methods: {
    getUserInfo () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetUserInfo', getLocalStore().UserID).then(() => {
        this.firmInfo = this.$store.getters.userInfo
        this.CompanyType = this.firmInfo.CompanyType.split('|')
        this.ProductType = this.firmInfo.ProductType.split('|')
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch(() => {
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    updateData () {
      if (this.checkForm()) {
        this.$store.dispatch('SetLoadingStatus', true)
        this.firmInfo.userid = getLocalStore().UserID
        this.firmInfo.CompanyType = this.CompanyType.join('|')
        this.firmInfo.ProductType = this.ProductType.join('|')
        this.$store.dispatch('UpdateUserInfo', this.firmInfo).then(() => {
          this.dialogmsg = 'savedsuccessfully'
          this.$store.dispatch('SetLoadingStatus', false)
          this.$bvModal.show('companyInfoDialog')
          this.isFormDisabled = true
        }).catch(() => {
          this.$store.dispatch('SetLoadingStatus', false)
          this.isFormDisabled = true
        })
      }
    },
    checkContact () {
      if (!this.firmInfo.ContactPersonName || !this.firmInfo.ContactPersonName.replace(/\s*/g, '')) {
        this.checkField.contactpersonnameinfo = '联系人不能为空'
        return false
      } else {
        this.checkField.contactpersonnameinfo = ''
        return true
      }
    },
    checkPhone () {
      if (!this.firmInfo.CompanyPhoneNumber) {
        this.checkField.companyphoneinfo = '手机不能为空'
        return false
      } else if (/^[\u4e00-\u9fa5_a-zA-Z]+$/.test(this.firmInfo.CompanyPhoneNumber) && this.firmInfo.CompanyPhoneNumber.length !== 11) {
        this.checkField.companyphoneinfo = '手机号有误'
        return false
      } else {
        this.checkField.companyphoneinfo = ''
        return true
      }
    },
    checkEmail () {
      if (!this.firmInfo.CompanyEmail) {
        this.checkField.companyemailinfo = '邮箱不能为空'
        return false
      } else if (!/(\S)+[@]{1}(\S)+[.]{1}(\w)+/.test(this.firmInfo.CompanyEmail)) {
        this.checkField.companyemailinfo = '邮箱格式有误'
        return false
      } else {
        this.checkField.companyemailinfo = ''
        return true
      }
    },
    checkForm () {
      return this.checkContact() && this.checkPhone() && this.checkEmail()
    }
  }
}
</script>

<style lang="scss" scoped>

$cell-margin-right: 20px;

.box-company-info {
  width: 963px;
  height: 1000px;
  background-color: #ffffff;
  border-radius: 20px;
  color: #535353;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 30px  0 20px 30px;
}

.box-company-info > div:first-child {
  margin: 20px 0 10px 0;
  font-size: 20px;
}

.box-group {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15px;
}

.box-group > label {
  width: 160px;
  font-size: 14px;
  text-align: right;
}

input {
  width: 488px;
  height: 35px;
  margin-left: $cell-margin-right;
  background-color: #EDEDED;
}

select {
  width: 160px;
}

.checkbox-style {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  margin-top: 4px;
}

.checkbox-style > input {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid #707070;
  margin-right: 5px;
  margin-left: 0;
}

.checkbox-style > span {
  width: 200px;
}

.checkbox-style > span > input {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid #707070;
  margin-right: 5px;
  margin-left: 0;
}

.btn-one {
  width: 492px;
  height: 35px;
  border-radius: 8px;
  background-color: #4285F4;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-left: $cell-margin-right;
}

.btn-two {
  width: 295px;
  height: 35px;
  border-radius: 8px;
  background-color: #4285F4;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-left: $cell-margin-right;
}

.btn-three {
  width: 168px;
  height: 35px;
  border-radius: 8px;
  background-color: #2054A9;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-left: 29px;
}

.form-control {
  font-size: 14px;
  background-color: #EDEDED;
}

.require {
  color: red;
}

/*占位符修改*/
input::-webkit-input-placeholder { /* WebKit browsers */
  font-size: 10px;
  color: #CCCCCC;
}

input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-size: 10px;
  color: #CCCCCC;
}

input::-moz-placeholder { /* Mozilla Firefox 19+ */
  font-size: 10px;
  color: #CCCCCC;
}

input:-ms-input-placeholder { /* Internet Explorer 10+ */
  font-size: 10px;
  color: #CCCCCC;
}
</style>
